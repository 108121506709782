// @ts-strict-ignore
import camelcaseKeys from 'camelcase-keys';
import cache from 'lscache';

import store from '../store/store';
import translate from '../utils/i18n/SwappingIntlProvider';
import { cacheErrorLog, createLog } from './logger';
import { buildHeaders, buildRequest, buildUrl, fetchRequest } from '../utils/apis/RestApi';
import { getUserLocale, handle404Errors, handleError } from '../business/helpers';
import { getModelsInformation } from './spid';
import { getProductNatureLabelsByIds } from './dictionaries';
import { minutesToMidnight } from '../utils/utils';
import { Pogos, translateFromPogo } from './interventions/intervention.const';
import { isInterventionOgea } from '../business/entityHelper';
import { ogeaLabel, payableLabel, warrantyLabel } from '../business/priceHelper';
import { type Language } from './customer/customer.types';
import { type Locale } from '../store/i18nSlice';
import {
  LevelType,
  type ILinkCategory,
  type ILinkCategoryRaw,
  type ILinkEntityResponse,
  type ILinkProductResponse,
  type Thumbnail,
  type ILinkProcess,
  type Entity,
  type InterventionNature,
  type ILinkSparePartNature,
  type ILinkProduct,
  type ILinkServicesNature,
  type ProductInformation,
  type ILinkNatureCategory,
  type ILinkLocale,
  type ILinkSparePartsAndServicesNature,
  type IlinkGroupProductNaturesContent,
  type ILinkEntity,
  type CompatibilitySparePartNature,
} from './ilink.type';
import { type ModelInformation } from './spid.type';
import { iLinkLifeStages, LifeStage } from '../business/articleHelper';
import { getIsInterventionWarrantyOnly } from '../store/selectors/intervention';
import { isCustomProduct } from '../store/selectors/catalog';
import { getPogoToUse } from '../business/pogoHelper';

export const iLinkRanges = [0, 1, 2, 3, 4];

export const getCountryId = () => store.getState().userInfo.countryId;
const logger = createLog('api/ilink');

const iLinkStorageKey = 'iLink';

const verifyHasNoResult = (status: number | undefined) => status === 204;

export const NATURE_ID_WITHOUT_SPARE_PART = 9999;

const getNextLevel = (actualLevel: LevelType) => {
  switch (actualLevel) {
    case LevelType.processes:
      return LevelType.categories;
    case LevelType.categories:
      return LevelType.products;
    default:
      return LevelType.product;
  }
};

export const getIlinkCategoriesFromProcess = (processCode: number): Promise<ILinkCategory[]> => {
  const key = `${iLinkStorageKey}-categories-from-process-${processCode}`;
  const storedCategories = cache.get(key);
  if (storedCategories) {
    return Promise.resolve(JSON.parse(storedCategories));
  }
  const url = buildUrl('ICARE_BACK', `ilink/categories_from_processes/${processCode}/${getCountryId()}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return [];
      }
      return response.json();
    })
    .then((iLinkCategory: ILinkCategoryRaw[]) => {
      if (!iLinkCategory || !iLinkCategory.length) {
        return [];
      }
      const categories: ILinkCategory[] = iLinkCategory.map(category => ({
        id: category.id,
        name: category.label,
        imageUrl: category.image_url,
      }));
      try {
        cache.set(key, JSON.stringify(categories), minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return categories;
    })
    .catch(() => handleError('ILink Request - GET catalog categories'));
};

export const getProductsFromCatalog = (entityId: number): Promise<ILinkEntityResponse[]> => {
  const path = `ilink/v1/categories/${entityId}/products?country_number=${getCountryId()}&include_product_without_spare_parts=false`;
  const url = buildUrl('ICARE_BACK', path);
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return { product_ids: [] };
      }
      return response.json();
    })
    .then((result: ILinkProductResponse) => result.product_ids.map(id => ({ model_code: id })))
    .catch(error => handle404Errors(error, logger, url, []));
};

const getProcessesOrCategoriesFromCatalog = (processId?: number): Promise<ILinkEntityResponse[]> => {
  const countryId = getCountryId();
  const path = processId ? `categories_from_processes/${processId}/${countryId}` : `processes/${countryId}`;
  const url = buildUrl('ICARE_BACK', `ilink/${path}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request).then(response => {
    if (verifyHasNoResult(response.status)) {
      return [];
    }
    return response.json();
  });
};

const furtherRanking = 999;

export const orderEntitiesByRanking = (ilinkEntities: ILinkEntityResponse[]) => {
  const entities: ILinkEntity[] = ilinkEntities.map(entity => ({
    id: entity.id || entity.model_code,
    label: entity.label || entity.id?.toString() || '',
    imageUrl: entity.image_url,
    ranking: entity.ranking,
  }));

  return entities
    .reduce<(typeof entities)[]>(
      (orderedEntities, entity) => {
        // eslint-disable-next-line no-nested-ternary
        const position = entity.ranking ? (entity.ranking === furtherRanking ? 2 : 0) : 1;
        orderedEntities[position].push(entity);
        return orderedEntities;
      },
      [[], [], []]
    )
    .map((entity, index) =>
      index === 0 ? entity.sort((a, b) => (a.ranking ?? 0) - (b.ranking ?? 0)) : entity.sort((a, b) => a.label.localeCompare(b.label))
    )
    .flat();
};

export const getILinkCatalog = (actualLevel: LevelType, entityId?: number): Promise<Thumbnail> => {
  const entityKey = entityId ? `-${entityId}` : '';
  const key = `${iLinkStorageKey}-${actualLevel}${entityKey}`;
  const storedCatalog = cache.get(key);

  if (storedCatalog) {
    return Promise.resolve(JSON.parse(storedCatalog));
  }

  const promise = actualLevel === LevelType.products && entityId ? getProductsFromCatalog(entityId) : getProcessesOrCategoriesFromCatalog(entityId);

  return promise
    .then((ilinkEntities: ILinkEntityResponse[]) => {
      const entities =
        actualLevel === LevelType.products
          ? ilinkEntities.map(ilinkEntity => ({ id: ilinkEntity.model_code, label: ilinkEntity.label }))
          : orderEntitiesByRanking(ilinkEntities);
      return {
        nextLevel: getNextLevel(actualLevel),
        entities,
      };
    })
    .then(thumbnail => {
      if (thumbnail.nextLevel === LevelType.product) {
        const entitiesIndex = thumbnail.entities.reduce((acc: Record<string, boolean>, curr) => {
          acc[curr.id.toString()] = true;
          return acc;
        }, {});

        return getModelsInformation(thumbnail.entities.map(entity => entity.id)).then(entities => ({
          ...thumbnail,
          entities: [...entities]
            .sort((a, b) => (b.saleYear ?? 0) - (a.saleYear ?? 0))
            .reduce((acc: ILinkEntity[], entity: ModelInformation) => {
              if (entitiesIndex[entity.modelCode]) {
                acc.push({
                  id: entity.modelCode,
                  label: entity.label,
                  imageUrl: entity.imageUrl,
                });
              }
              return acc;
            }, []),
        }));
      }

      return thumbnail;
    })
    .then(thumbnail => {
      try {
        cache.set(key, JSON.stringify(thumbnail), minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return thumbnail;
    })
    .catch(() => handleError('ILink Request - GET catalog entities'));
};

export const getILinkProcesses = (): Promise<ILinkProcess[]> =>
  getILinkCatalog(LevelType.processes).then(processes => {
    const formattedProcesses: ILinkProcess[] = processes.entities.map(process => ({
      id: process.id,
      name: process.label || process.id?.toString(),
    }));
    return formattedProcesses;
  });

export const chunkArray = <T extends Object>(array: T[], chunkSize: number): Array<T[]> => {
  const results = [];
  const arrayToChunk = [...array];
  while (arrayToChunk.length) {
    results.push(arrayToChunk.splice(0, chunkSize));
  }
  return results;
};

export const articleBasedOnType = () => {
  const state = store.getState();
  const isWarrantyOnly = getIsInterventionWarrantyOnly(state);
  const context = state.intervention.contextType?.authorizedPogos;
  const type = state.intervention.interventionType;
  let value = {
    priceType: payableLabel(),
    gridValueId: Pogos.PAYANT,
    free: false,
  };
  if (isWarrantyOnly) {
    value = {
      priceType: warrantyLabel(),
      gridValueId: Pogos.GARANTI,
      free: true,
    };
  }

  if (isInterventionOgea(type)) {
    value = {
      priceType: ogeaLabel(),
      gridValueId: Pogos['ASSURANCE OGEA'],
      free: true,
    };
  }

  if (context) {
    const pogoToUse = getPogoToUse(context, false);
    if (pogoToUse) {
      value.gridValueId = translateFromPogo(pogoToUse);
    }
  }

  return value;
};

const buildEntity = (model: ModelInformation): Entity => ({
  modelCode: model.modelCode,
  name: model.label,
  imageUrl: model.imageUrl,
  quantity: 0,
  articles: [],
});

export const getSparePartsByIds = (sparePartsIds: number[], related?: boolean): Promise<Entity[]> =>
  getModelsInformation(sparePartsIds).then(entities => entities.map(entity => ({ ...buildEntity(entity), related })));

export const sortNatures = (entities: InterventionNature[]) => [...entities].sort((a, b) => a.natureTitle.localeCompare(b.natureTitle));

const sortAndCacheNatureEntities = (entities: InterventionNature[], key: string) => {
  const sortedNatures = sortNatures(entities);
  try {
    cache.set(key, sortedNatures, minutesToMidnight());
  } catch (error) {
    cacheErrorLog(error);
  }
  return sortedNatures;
};

export const getHasSpareParts = (productId?: number, categoryId?: number): Promise<boolean> => {
  let key = '';
  let url = '';
  const pathParams = `/spare_parts?include_hypothesis=true&country_number=${getCountryId()}&life_stage=${iLinkLifeStages}`;
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);

  if (productId) {
    key = `${iLinkStorageKey}-product-${productId}-has-spare-parts`;
    url = buildUrl('ICARE_BACK', `ilink/v1/products/${productId}${pathParams}`);
  } else {
    key = `${iLinkStorageKey}-category-${categoryId}-has-spare-parts`;
    url = buildUrl('ICARE_BACK', `ilink/v1/categories/${categoryId}${pathParams}`);
  }

  const storedHasSpareParts = cache.get(key);
  if (storedHasSpareParts) {
    return Promise.resolve(storedHasSpareParts);
  }

  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return [];
      }
      return response.json();
    })
    .then((iLinkNatures: ILinkSparePartNature[]) => {
      const result = !!iLinkNatures.length;
      try {
        cache.set(key, result, minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return result;
    })
    .catch(() => handleError('ILink Request - GET has spare parts'));
};

export const getSparePartsByServiceId = (serviceId: number): Promise<{ spareParts: Entity[]; natureId: number }> => {
  const url = buildUrl('ICARE_BACK', `ilink/v1/services/${serviceId}/spare_parts?country_number=${getCountryId()}&life_stage=${iLinkLifeStages}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return null;
      }
      return response.json();
    })
    .then((sparePartsNatures: ILinkSparePartNature[] | null) => {
      if (sparePartsNatures === null) {
        return { spareParts: [], natureId: NATURE_ID_WITHOUT_SPARE_PART };
      }

      const sparePartIds = sparePartsNatures.flatMap(sparePartNature => sparePartNature.spare_part_models);
      return getSparePartsByIds(sparePartIds).then(spareParts => ({
        spareParts,
        natureId: sparePartsNatures[0].spare_part_nature_id,
      }));
    })
    .catch(() => handleError('ILink Request - GET spare parts by service id'));
};

export const getSparePartsByCategoryAndNature = (categoryId: number, natureId: number, isProductCustom = false): Promise<Entity[]> => {
  const key = `${iLinkStorageKey}-spareParts-from-category-${categoryId}-and-nature-${natureId}${isProductCustom && '-customProduct'}`;
  const storedSparePartModels = cache.get(key);
  if (storedSparePartModels) {
    return Promise.resolve(storedSparePartModels);
  }
  const locale = getUserLocale();
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl('ICARE_BACK', `ilink/v1/spareparts_by_category_and_nature/${locale}/${getCountryId()}/${categoryId}/${natureId}`);
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => response.json())
    .then((spareParts: Entity[]) => {
      let result = spareParts;
      if (isProductCustom) {
        const partsToDisplay = [...spareParts].slice(0, 4).map(sparePart => ({ ...sparePart, related: false }));
        result = [...partsToDisplay, ...spareParts.slice(4)];
      }
      try {
        cache.set(key, result, minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return result;
    });
};

export const getCompatibilitySpareParts = (productId: number): Promise<InterventionNature[]> => {
  const key = `${iLinkStorageKey}-v2-spare-parts-${productId}`;
  const headers = buildHeaders('ICARE_BACK', 'Bearer');
  const url = buildUrl(
    'ICARE_BACK',
    `ilink/v2/products/${productId}/spare_parts?include_hypothesis=true&country_number=${getCountryId()}&life_stage=${iLinkLifeStages}`
  );
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return [];
      }
      return response.json();
    })
    .then((compatibilitySparePartNature: CompatibilitySparePartNature[]) => {
      const natureIds = compatibilitySparePartNature.map(nature => nature.spare_part_nature_id);
      const compatibilitySpareParts = compatibilitySparePartNature.flatMap(nature => nature.spare_part_models);

      return Promise.all([
        getProductNatureLabelsByIds(natureIds),
        getSparePartsByIds(compatibilitySpareParts.map(sparePart => sparePart.spare_part_id)),
      ]).then(([natures, spareParts]) =>
        compatibilitySparePartNature.map(iLinkNature => {
          const compatibilityModelSpareParts = iLinkNature.spare_part_models;
          const natureSpareParts = spareParts.filter(sparePart =>
            compatibilityModelSpareParts.some(model => model.spare_part_id === sparePart.modelCode)
          );
          const natureToGet = natures.find(nature => iLinkNature.spare_part_nature_id === nature.id);
          const result: InterventionNature = {
            natureId: iLinkNature.spare_part_nature_id,
            natureTitle: natureToGet?.value ?? iLinkNature.spare_part_nature_id.toString(),
            spareParts: natureSpareParts.map(natureSparePart => {
              const compatibilitySparePart = compatibilityModelSpareParts.find(comp => comp.spare_part_id === natureSparePart.modelCode);
              return {
                ...natureSparePart,
                compatibility: compatibilitySparePart?.compatibility,
                hypothesis: compatibilitySparePart?.hypothesis,
              };
            }),
            services: [],
          };
          return result;
        })
      );
    })
    .then(natureEntities => sortAndCacheNatureEntities(natureEntities, key))
    .catch(() => handleError('ILink Request - GET compatible spare parts'));
};

export const getSpareParts = (product: ILinkProduct, categoryId?: number, numberOfSparePart?: number): Promise<InterventionNature[]> => {
  let key = `${iLinkStorageKey}-category-spare-parts-${categoryId}`;
  let path = `categories/${categoryId}`;

  if (!isCustomProduct(product)) {
    key = `${iLinkStorageKey}-spare-parts-${product.id}`;
    path = `products/${product.id}`;
  }

  if (path.includes('undefined')) {
    const state = store.getState();
    logger.log(new Error('Category is not defined, cannot get spare parts'), {
      productId: product.id,
      custom: product.isCustom,
      docNumber: state.intervention?.footer?.docNumber,
    });

    return Promise.resolve([]);
  }

  const storedSpareParts = cache.get(key);
  if (storedSpareParts) {
    return Promise.resolve(storedSpareParts);
  }

  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const url = buildUrl(
    'ICARE_BACK',
    `ilink/v1/${path}/spare_parts?include_hypothesis=true&country_number=${getCountryId()}&life_stage=${iLinkLifeStages}`
  );
  const request = buildRequest(url, 'GET', headers);

  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return [];
      }
      return response.json();
    })
    .then((iLinkSparePartsNatures: ILinkSparePartNature[]) => {
      const natureIds = iLinkSparePartsNatures.map(nature => nature.spare_part_nature_id);
      const sparePartIds = iLinkSparePartsNatures.flatMap(nature => nature.spare_part_models);

      return Promise.all([getProductNatureLabelsByIds(natureIds), getSparePartsByIds(sparePartIds)]).then<InterventionNature[]>(
        ([natures, spareParts]) =>
          iLinkSparePartsNatures.map(iLinkNature => {
            const natureSpareParts = spareParts.filter(sparePart => iLinkNature.spare_part_models.includes(sparePart.modelCode));
            const natureToGet = natures.find(nature => iLinkNature.spare_part_nature_id === nature.id);
            return {
              natureId: iLinkNature.spare_part_nature_id,
              natureTitle: natureToGet?.value ?? iLinkNature.spare_part_nature_id.toString(),
              services: [],
              spareParts: natureSpareParts.map((sparePart, index) => ({
                ...sparePart,
                ...(!!numberOfSparePart && index >= numberOfSparePart ? { related: true } : {}),
              })),
            };
          })
      );
    })
    .then(natureEntities => sortAndCacheNatureEntities(natureEntities, key))
    .catch(() => handleError('ILink Request - GET spare parts'));
};

export const getSparePartsByNatureId = (natureId: number, sparePartsToFilter: number[]): Promise<Entity[]> => {
  const key = `${iLinkStorageKey}-category-spare-parts-${natureId}`;
  const storedSpareParts = cache.get(key);
  if (storedSpareParts) {
    return Promise.resolve(storedSpareParts);
  }
  const path = `ilink/v1/product_nature_families/${natureId}/spare_parts?country_number=${getCountryId()}&life_stage=${iLinkLifeStages}`;
  const url = buildUrl('ICARE_BACK', path);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return [];
      }
      return response.json();
    })
    .then((iLinkSparePartNatures: ILinkSparePartNature[]) => {
      const nature = iLinkSparePartNatures.find(iLinkSparePartNature => iLinkSparePartNature.spare_part_nature_id === natureId);
      const sparePartIds = [...new Set(nature?.spare_part_models)];
      if (sparePartIds.length) {
        return getSparePartsByIds(sparePartIds.filter(sparePartId => !sparePartsToFilter.includes(sparePartId)));
      }

      return [] as Entity[];
    })
    .then(spareParts => {
      const relatedSpareParts = spareParts.map(sparePart => ({ ...sparePart, related: true }));
      try {
        cache.set(key, relatedSpareParts, minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return relatedSpareParts;
    })
    .catch(() => handleError('ILink Request - GET spare parts by category id and nature id'));
};

export const getNaturesBySparePartAndServiceIds = (sparePartIds: number[], serviceIds: number[]): Promise<ILinkSparePartsAndServicesNature> => {
  const basePath = new URLSearchParams();
  serviceIds.length && basePath.set('services', serviceIds.toString());
  sparePartIds.length && basePath.set('spare_parts', sparePartIds.toString());
  const url = buildUrl('ICARE_BACK', `ilink/natures_by_spareparts_and_services?${basePath}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return { spareParts: [], services: [] };
      }
      return response.json();
    })
    .catch(() => handleError('ILink Request - GET nature ID by service Ids and spare parts Ids'));
};

export const getServices = (product: ILinkProduct, hasSpareParts?: boolean, categoryId?: number): Promise<InterventionNature[]> => {
  let key = `${iLinkStorageKey}-category-services-${categoryId}`;
  let path = `categories/${categoryId}`;

  if (!isCustomProduct(product) && hasSpareParts) {
    key = `${iLinkStorageKey}-services-${product.id}`;
    path = `products/${product.id}`;
  }
  if (path.includes('undefined')) {
    const state = store.getState();
    logger.log(new Error('Category is not defined, cannot get services'), {
      productId: product?.id,
      hasSpareParts,
      custom: product?.isCustom,
      docNumber: state.intervention?.footer?.docNumber,
    });

    return Promise.resolve([]);
  }

  const storedServices = cache.get(key);
  if (storedServices) {
    return Promise.resolve(storedServices);
  }

  const builtUrl = `ilink/v1/${path}/services?country_number=${getCountryId()}&range=${iLinkRanges}&life_stage=${LifeStage.active}`;
  const url = buildUrl('ICARE_BACK', builtUrl);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  const withoutNatureLabel = translate('dashboard.results.nature.empty').toUpperCase();
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return [];
      }
      return response.json();
    })
    .then((iLinkNatures: ILinkServicesNature[]) => {
      const serviceIdsPromise = getModelsInformation([...new Set(iLinkNatures.flatMap(nature => nature.service_ids))]).then(entities =>
        entities.map(buildEntity)
      );
      const natureIds = [
        ...new Set(
          iLinkNatures.filter(nature => nature.spare_part_nature_id !== NATURE_ID_WITHOUT_SPARE_PART).map(nature => nature.spare_part_nature_id)
        ),
      ];
      const naturesPromise = getProductNatureLabelsByIds(natureIds);
      return Promise.all([serviceIdsPromise, naturesPromise]).then(([services, natures]) =>
        iLinkNatures.map(
          nature =>
            ({
              natureId: nature.spare_part_nature_id,
              natureTitle:
                nature.spare_part_nature_id === NATURE_ID_WITHOUT_SPARE_PART
                  ? withoutNatureLabel
                  : natures.find(n => n.id === nature.spare_part_nature_id)?.value || nature.spare_part_nature_id.toString(),
              services: services.filter(service => nature.service_ids.includes(service.modelCode)).sort((a, b) => a.name.localeCompare(b.name)),
              spareParts: [],
            } as InterventionNature)
        )
      );
    })
    .then(servicesNatures => sortAndCacheNatureEntities(servicesNatures, key))
    .catch(() => handleError('ILink Request - GET services'));
};

export const getProcessByService = (serviceId: number): Promise<ILinkProcess> => {
  const url = buildUrl('ICARE_BACK', `ilink/v1/services/${serviceId}/categories?country_number=${getCountryId()}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then(iLinkProcess => {
      const process: ILinkProcess = {
        name: iLinkProcess[0].process_label,
        id: iLinkProcess[0].process_id,
      };

      return process;
    })
    .catch(() => handleError('ILink Request - GET process by service'));
};

export const getProductCodesFromProcessAndOrCategory = (processCode: number, categoryCode?: number): Promise<number[]> => {
  const path = categoryCode ? `categories/${categoryCode}` : `processes/${processCode}`;
  const url = buildUrl('ICARE_BACK', `ilink/v1/${path}/products?country_number=${getCountryId()}&include_product_without_spare_parts=false`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return { product_ids: [] };
      }
      return response.json();
    })
    .then((results: ILinkProductResponse) => results.product_ids)
    .catch(() => handleError('ILink Request - GET products codes from process and category'));
};

export const getProductInformation = (productModelCode: number): Promise<ProductInformation | null> => {
  const key = `${iLinkStorageKey}-product-information-${productModelCode}`;
  const storedProductInformation = cache.get(key);
  if (storedProductInformation) {
    return Promise.resolve(JSON.parse(storedProductInformation));
  }

  const url = buildUrl('ICARE_BACK', `ilink/v1/products/${productModelCode}/categories?country_number=${getCountryId()}`);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return null;
      }
      return response.json();
    })
    .then(processes => {
      const result: ProductInformation | null =
        processes?.length > 0
          ? {
              process: {
                id: processes[0].process_id,
                name: processes[0].process_label,
              },
              category: {
                id: processes[0].categories[0].id,
                name: processes[0].categories[0].label,
              },
            }
          : null;
      if (result) {
        try {
          cache.set(key, JSON.stringify(result), minutesToMidnight());
        } catch (error) {
          cacheErrorLog(error);
        }
      }
      return result;
    })
    .catch(() => handleError('ILink Request - GET product info'));
};

export const getILinkNatureCategories = (processId?: number): Promise<ILinkNatureCategory[]> => {
  const key = `${iLinkStorageKey}-nature-categories-${processId ? processId.toString() : ''}`;
  const storedProductInformation = cache.get(key);

  if (storedProductInformation) {
    return Promise.resolve(JSON.parse(storedProductInformation));
  }

  let path = 'ilink/v1/group_product_natures';
  if (processId) {
    path = `${path}?specific_to_processes=${processId}`;
  }
  const url = buildUrl('ICARE_BACK', path);
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => {
      if (verifyHasNoResult(response.status)) {
        return { content: [] };
      }
      return response.json();
    })
    .then(({ content }) => camelcaseKeys(content, { deep: true }))
    .then((iLinkNatureCategories: IlinkGroupProductNaturesContent[]) =>
      iLinkNatureCategories.map((iLinkNatureCategory: any) => ({
        id: iLinkNatureCategory.id,
        label: iLinkNatureCategory.label.toUpperCase(),
        imageUrl: iLinkNatureCategory.imageUrl,
        natureIds: iLinkNatureCategory.groupProductNatureAssociations.map((nature: any) => nature.productNatureId),
      }))
    )
    .then(result => {
      if (result.length > 0) {
        try {
          cache.set(key, JSON.stringify(result), minutesToMidnight());
        } catch (error) {
          cacheErrorLog(error);
        }
      }
      return result;
    })
    .catch(() => handleError('ILink Request - GET spare parts categories'));
};

export const getILinkAvailableLocales = (appDefaultLanguage: Locale): Promise<Language[]> => {
  const key = `${iLinkStorageKey}-locales`;
  const storedILinkLocales = cache.get(key);
  if (storedILinkLocales) {
    return Promise.resolve(storedILinkLocales);
  }

  const url = buildUrl('ICARE_BACK', 'ilink_locales');
  const headers = buildHeaders('ICARE_BACK', 'Bearer', true);
  const request = buildRequest(url, 'GET', headers);
  return fetchRequest(request)
    .then(response => response.json())
    .then((locales: ILinkLocale[]) => {
      const result = locales.map(locale => {
        const [language, country] = locale.code.split('-');
        return {
          country,
          language: language.toUpperCase(),
          default: language.toUpperCase() === appDefaultLanguage.language && country === appDefaultLanguage.country,
        };
      });
      try {
        cache.set(key, result, minutesToMidnight());
      } catch (error) {
        cacheErrorLog(error);
      }
      return result;
    })
    .catch(() => handleError('ILink Request - GET ILink available locales'));
};
