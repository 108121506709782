import { type BaseQueryApi, type BaseQueryFn, type FetchArgs } from '@reduxjs/toolkit/query';

import { type RootState } from '../../store/store';
import { fetchRequest } from '../../utils/apis/RestApi';
import { selectLocaleToString } from '../../store/selectors/i18n';
import HttpError from '../../utils/apis/HttpError';

const prepareHeaders = (headers: Headers, { getState }: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>) => {
  const rootState = getState() as RootState;
  const token = rootState.configuration.token;
  const locale = selectLocaleToString(rootState);

  headers.set('Authorization', `Bearer ${token}`);
  headers.set('Accept-Language', locale);
};

interface FetchArgsBaseQuery extends Omit<FetchArgs, 'headers'> {
  headers?: Headers;
}

export const buildICareBackBaseQuery =
  (resourcePath: string): BaseQueryFn<FetchArgsBaseQuery> =>
  async ({ url, params, method, body, headers }, api) => {
    const iCareHeaders = new Headers(headers);
    const iCareBackUrl = (api.getState() as RootState).configuration.config.services.ICARE_BACK.url;

    prepareHeaders(iCareHeaders, api);

    try {
      const urlSearchParams = new URLSearchParams(params);

      const result = await fetchRequest(
        new Request(`${iCareBackUrl}${resourcePath}${url}?${urlSearchParams}`, {
          method,
          headers: iCareHeaders,
          body,
          credentials: 'include',
        })
      );

      return { data: await result.json() };
    } catch (error) {
      if (error instanceof HttpError) {
        return {
          error: {
            status: error.response?.status,
            data: (await error.response?.json()) || error.message,
          },
        };
      }

      return {
        error: {
          status: 500,
          data: String(error),
        },
      };
    }
  };
