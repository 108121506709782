import { createSelector } from '@reduxjs/toolkit';

import { type RootState } from '../store';
import { PersonTypes } from '../../apis/customer/customer.types';
import { translatePogoToPogoId, PAYABLE_POGO_ID, WARRANTY_POGO_ID } from '../../apis/interventions/intervention.const';
import { InterventionType, type PogoDetail } from '../../apis/interventions/intervention.type';
import { InterventionStatus } from '../../business/interventionButtonMenu';
import { selectStoreId } from './userInfo';
import { EntityType } from '../../apis/entity.type';
import { getTotalAmount } from '../../business/priceHelper';
import { getPogoToUse, selectAuthorizedPogos } from '../../business/pogoHelper';

const hasPogo = (authorizedPogos: PogoDetail[], pogo: number) =>
  authorizedPogos.some(pogoItem => (pogoItem.linkedPogoId ?? pogoItem.pogoId) === pogo);

export const isPogoAuthorized = (authorizedPogos: PogoDetail[], pogo: string | undefined): boolean => {
  if (getPogoToUse(authorizedPogos)) {
    return !!pogo && getPogoToUse(authorizedPogos) === translatePogoToPogoId(pogo);
  }
  return !!pogo && authorizedPogos.some(authorizedPogo => authorizedPogo.pogoId === translatePogoToPogoId(pogo));
};

export const getInterventionContextTypes = (state: RootState) =>
  state.workshopIntervention.workshopInterventionContextTypes?.filter(currentType =>
    state.customer.customer?.memberId ? !currentType.internal : currentType.internal
  );

export const selectWorkshopInterventionContextTypes = (state: RootState) => state.workshopIntervention.workshopInterventionContextTypes;

export const getIsInternalIntervention = (state: RootState) =>
  state.customer.customer?.personType === PersonTypes.decathlon &&
  [InterventionType.standard, InterventionType.new].includes(state.intervention.interventionType);

export const getIsDefaultContextType = (state: RootState) =>
  !getIsInternalIntervention(state) &&
  state.intervention.interventionType !== InterventionType.homeService &&
  getInterventionContextTypes(state)?.length > 1 &&
  (!state.intervention.contextType || state.intervention.contextType.id === getInterventionContextTypes(state)[0].id);

const getNatureEntity = (entity: EntityType) => (state: RootState) => state.intervention.natures.flatMap(nature => nature[entity]);

export const selectInterventionSpareParts = createSelector(getNatureEntity(EntityType.spareParts), services => services);

export const selectInterventionServices = createSelector(getNatureEntity(EntityType.services), services => services);

export const selectRealizationSite = (state: RootState) => state.intervention.realizationSite;
export const selectRealizationSiteName = (state: RootState) => state.intervention.realizationSite?.name;

export type ActionsLabelsState = ReturnType<typeof selectInterventionButtonMenuState>;

export const selectInterventionButtonMenuState = (state: RootState) => ({
  stateId: state.intervention.footer.state.stateId,
  hasShortage: state.intervention.natures.flatMap(nature => nature.spareParts).some(sparePart => sparePart.orderInProgress),
  interventionType: state.intervention.interventionType,
  isRealizationSite: state.intervention.realizationSite?.id === selectStoreId(state),
  isCreationSite: state.intervention.creationSite?.id === selectStoreId(state),
  isInvoice: state.intervention.footer.hasInvoice,
  isInternal: getIsInternalIntervention(state),
  isPayablePogo: hasPogo(selectAuthorizedPogos(state), PAYABLE_POGO_ID),
  isTotalFree: getTotalAmount(selectInterventionSpareParts(state), selectInterventionServices(state)) === 0,
});

export const selectOnlyWepIntervention = (state: RootState) => state.intervention.interventionType === InterventionType.web;

export const getIsInterventionWarrantyOnly = (state: RootState) =>
  state.intervention.interventionType === InterventionType.homeService ||
  (getIsInternalIntervention(state) && hasPogo(selectAuthorizedPogos(state), WARRANTY_POGO_ID));

export const interventionNotStarted = (state: RootState) =>
  state.intervention.natures.flatMap(nature => nature.spareParts).length === 0 &&
  state.intervention.natures.flatMap(nature => nature.services).length === 0 &&
  (state.intervention.footer.state.stateId === InterventionStatus.inCreation ||
    state.intervention.footer.state.stateId === InterventionStatus.notStarted);

export const selectInterventionStarted = (state: RootState) => state.intervention.footer.state.stateId !== InterventionStatus.notStarted;

export const isExistingIntervention = (state: RootState) => state.intervention.footer.state.stateId !== InterventionStatus.inCreation;

export const selectInterventionDocNumber = (state: RootState) => state.intervention.footer.docNumber;

export const selectIsInCreation = (state: RootState) =>
  state.intervention.footer.state.stateId === InterventionStatus.inCreation || !state.intervention.footer.state.stateId;

export const selectIntervention = (state: RootState) => state.intervention;

export const selectIsFromTraceability = (state: RootState) => state.intervention.fromTraceability;

export const selectInterventionNatures = (state: RootState) => state.intervention.natures;

export const selectInterventionStateId = (state: RootState) => state.intervention.footer.state.stateId;

export const selectSpareParts = (state: RootState) => state.intervention.natures.flatMap(nature => nature.spareParts);

export const selectServices = (state: RootState) => state.intervention.natures.flatMap(nature => nature.services);

export const selectContextType = (state: RootState) => state.intervention.contextType;

export const selectInterventionType = (state: RootState) => state.intervention.interventionType;

export const selectInterventionModalsDisplay = (state: RootState) => state.intervention.modalsDisplay;

export const selectInterventionFooter = (state: RootState) => state.intervention.footer;

export const selectMissingFields = (state: RootState) => state.intervention.missingFields;

export const selectInterventionFileNames = (state: RootState) => state.intervention.fileNames;

export const selectInterventionCreationSite = (state: RootState) => state.intervention.creationSite;

export const selectInterventionReload = (state: RootState) => state.intervention.reload;
